<template>
	<div>
		<s-select-definition
			label="Presentación"
			:def="1274"
			@getDataInitial="getPresentations($event)"
			hidden
		></s-select-definition>
		<v-card>
			<v-card-title>
				{{ "Lectura de cajas" }}
			</v-card-title>
			<v-card-text>
				<v-row>
					<v-col>
						<s-text
							label="Codigo"
							v-model="item.Code"
							ref="textCodigo"
						></s-text>
					</v-col>
				</v-row>
				<v-row>
					<v-col
						v-for="(ele, index) in presentations"
						:key="index"
					>
						<v-btn
							color="primary"
							block
							@click="sendData(ele)"
						>
							{{ ele.DedAbbreviation }}
						</v-btn>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				items: [],
				item : {},
				presentations : []
			}
		},

		methods: {
			sendData(ele) {
				if(this.item.Code != "" && this.item.TypePresentation != "")
				{
					this.items.push(this.item);
					this.item.Code = "";
				}
				console.log('items agregados ', this.items);
				this.$refs.textCodigo.focus();
			},

			getPresentations(items)
			{
				this.presentations = items;
				console.log('presentaciones ', this.presentations);
			}
		},
		
		mounted () {
			this.$refs.textCodigo.focus();
		},
	}
</script>
